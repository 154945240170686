import * as React from 'react';

import BaseIcon, { IIconProps } from './BaseIcon';

export const UserIcon = (props: IIconProps) => {
  return (
    <BaseIcon {...props}>
      <svg
        className={props.classes ? props.classes : ''}
        aria-label={props.title ? props.title : 'Ícone de um usuário'}
        aria-hidden={props.hidden ? true : false}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 100 100"
      >
        <path d="M84.78 63.634c-6.258-5.857-13.94-7.584-18.746-8.076 4.002-5.102 6.485-12.079 6.485-19.78C72.52 20.202 62.416 7.532 50 7.532s-22.52 12.67-22.52 28.245c0 7.701 2.483 14.679 6.485 19.78-4.801.492-12.478 2.208-18.746 8.076C8.771 69.674 5.5 78.533 5.5 89.969a2.5 2.5 0 0 0 2.499 2.499h84.002a2.5 2.5 0 0 0 2.499-2.499c0-11.436-3.271-20.295-9.72-26.335zM50 12.53c9.663 0 17.522 10.428 17.522 23.248 0 12.818-7.859 23.243-17.522 23.243S32.478 48.595 32.478 35.777c0-12.819 7.859-23.247 17.522-23.247zM10.56 87.471c.42-8.756 3.126-15.531 8.046-20.156 8.025-7.553 19.477-6.946 19.622-6.946.27.021.523-.042.772-.104 3.266 2.322 6.988 3.753 11 3.753 4.018 0 7.745-1.431 11.011-3.759.254.067.508.13.768.109.124-.021 11.498-.664 19.585 6.905 4.94 4.635 7.657 11.415 8.077 20.197H10.56z" />
      </svg>
    </BaseIcon>
  );
};

UserIcon.displayName = 'UserIcon';

export default UserIcon;
