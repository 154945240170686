import { breakpoints } from '../styles/helpers/mediaQueries';

export const scrollToId = (id: string) => {
  const object = document.getElementById(id);

  if (object) {
    object.scrollIntoView({
      behavior: 'smooth',
    });
  }
};

export const isLargeScreen =
  typeof window !== 'undefined' && window.innerWidth > breakpoints.large;

export const toggleScroll = (canScroll: boolean) => {
  if (typeof document !== 'undefined' && document && document.documentElement) {
    if (canScroll) {
      document.documentElement.classList.remove('html_modal-open');
    } else {
      document.documentElement.classList.add('html_modal-open');
    }
  }
};
