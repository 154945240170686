import * as React from 'react';
import { Cross } from './slackIcons';
import { SlackModalContext } from './SlackModal';

export const SlackInput: React.SFC<{}> = () => {
  return (
    <SlackModalContext.Consumer>
      {({ toggleModal }) => (
        <button className="slack-input__wrapper" onClick={toggleModal}>
          <div className="slack-input__add-btn">
            <Cross />
          </div>
        </button>
      )}
    </SlackModalContext.Consumer>
  );
};

SlackInput.displayName = 'SlackInput';

export default SlackInput;
