import * as React from 'react';
import { debounce } from 'throttle-debounce';

import { ISlackMessage } from './SlackWrapper';
import { SlackModalContext } from './SlackModal';
import { ImageIcon } from './slackIcons';

interface IChatMessageState {
  canShowImg: boolean;
}

export class ChatMessage extends React.Component<
  ISlackMessage,
  IChatMessageState
> {
  wrapperRef: React.RefObject<HTMLDivElement>;
  throttledLoad: any;

  constructor(props: ISlackMessage) {
    super(props);
    this.state = {
      canShowImg: false,
    };
    this.wrapperRef = React.createRef();
    this.throttledLoad = debounce(150, this.loadImages);
  }

  loadImages: IntersectionObserverCallback = (entries, observer) => {
    if (entries[0].isIntersecting) {
      this.setState({ canShowImg: true });
      observer.disconnect();
    }
  };

  componentDidMount() {
    if (this.wrapperRef.current) {
      const observer = new IntersectionObserver(this.throttledLoad, {
        root: document.getElementById('slack-chat'),
        rootMargin: '10px 0px',
      });
      observer.observe(this.wrapperRef.current);
    }
  }

  render() {
    const { canShowImg } = this.state;
    const { user, files, text, reply_count } = this.props;
    const username = user.display_name || user.real_name;
    // Imagens servidas do servidor do gravatar (secure.gravatar)
    // têm seu link quebrado, então temos que usar uma imagem padrão no lugar
    const gravatarRegex = new RegExp(/secure.gravatar/gi);
    const profileImage = gravatarRegex.test(user.image_48)
      ? 'https://a.slack-edge.com/66f9/img/avatars/ava_0018-48.png'
      : user.image_48;
    return (
      <div className="slack-msg__wrapper" ref={this.wrapperRef}>
        <SlackModalContext.Consumer>
          {({ toggleModal }) => (
            <>
              <button
                className="slack-msg__img-wrapper"
                onClick={toggleModal}
                tabIndex={-1}
              >
                {canShowImg ? (
                  <img
                    alt={`Foto de ${user.real_name}`}
                    src={profileImage}
                    height={36}
                  />
                ) : (
                  <span aria-hidden={true} />
                )}
              </button>
              <div>
                <button
                  className="slack-msg__author"
                  onClick={toggleModal}
                  tabIndex={-1}
                >
                  {username}
                </button>
                <div
                  className="slack-msg__content"
                  dangerouslySetInnerHTML={{ __html: text }}
                  tabIndex={-1}
                />
                {files && files[0]
                  ? files.map(f => (
                      <div
                        className="slack-msg__file"
                        key={f}
                        onClick={toggleModal}
                        tabIndex={-1}
                      >
                        <ImageIcon /> {f}
                      </div>
                    ))
                  : null}
                {reply_count ? (
                  <button
                    className="slack-msg__replies"
                    onClick={toggleModal}
                    tabIndex={-1}
                  >
                    {reply_count} resposta
                    {reply_count > 1 ? 's' : null}
                  </button>
                ) : null}
              </div>
            </>
          )}
        </SlackModalContext.Consumer>
      </div>
    );
  }
}

export default ChatMessage;
