import * as React from 'react';

import { ISlackChannel, ISlackMessage } from './SlackWrapper';
import ChatMeta from './ChatMeta';
import ChatMessage from './ChatMessage';
import { slackColors } from './slackTheme';
import SlackInput from './SlackInput';
import { medias } from '../../styles/helpers/mediaQueries';
import { getRandomNumber } from '../../utils/strings';

import './slackChatStyles.css';

interface ISlackChannelState {
  excludedMessages: number;
  messages: ISlackMessage[];
}

export class SlackChat extends React.Component<
  ISlackChannel,
  ISlackChannelState
> {
  addMessageTimeout: any;

  constructor(props: ISlackChannel) {
    super(props);
    this.state = {
      excludedMessages: 10,
      messages: props.normalizedMessages && props.normalizedMessages.slice(10),
    };
  }

  addMessage = () => {
    if (this.state.excludedMessages > 0) {
      this.setState(prev => ({
        messages: this.props.normalizedMessages.slice(
          prev.excludedMessages - 1
        ),
        excludedMessages: prev.excludedMessages - 1,
      }));
      this.addMessageTimeout = window.setTimeout(
        this.addMessage,
        getRandomNumber(1000, 7000)
      );
    } else {
      window.clearTimeout(this.addMessageTimeout);
    }
  };

  componentWillUnmount() {
    window.clearInterval(this.addMessageTimeout);
  }

  componentDidMount() {
    this.addMessageTimeout = window.setTimeout(this.addMessage, 1500);
  }

  render() {
    const { props, state } = this;
    return (
      <div className="slack-chat__wrapper">
        <ChatMeta
          members={props.num_members}
          name={props.name}
          topic={props.topic && props.topic.value}
        />
        <div className="slack-chat__container" id="slack-chat">
          {state.messages.map(m => {
            if (!m.user || !m.user.real_name || !m.text) {
              return null;
            } else {
              return <ChatMessage key={m.ts} {...m} />;
            }
          })}
        </div>
        <SlackInput />
      </div>
    );
  }
}

export default SlackChat;
