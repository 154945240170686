import * as React from 'react';
import { StaticQuery, graphql } from 'gatsby';

import SlackSidebar from './SlackSidebar';
import SlackChat from './SlackChat';
import SlackModal, { SlackModalContext } from './SlackModal';
import SlackTopBar from './SlackTopBar';
import Button from '../Button';

import './slackStyles.css';

export interface ISlackUser {
  display_name?: string;
  real_name: string;
  image_48: string;
}

export interface ISlackMessage {
  ts: string;
  user: ISlackUser;
  text: string;
  reply_count?: number;
  files?: string[];
}

export interface ISlackChannel {
  name: string;
  num_members: number;
  topic?: {
    value: string;
  };
  normalizedMessages: ISlackMessage[];
}

export interface ISlackWrapperProps {
  includeCTA?: boolean;
}

export interface ISlackState {
  isModalOpen: boolean;
  hasAppeared: boolean;
}

export class SlackWrapper extends React.Component<
  ISlackWrapperProps,
  ISlackState
> {
  wrapperRef: React.RefObject<HTMLDivElement>;

  constructor(props: ISlackWrapperProps) {
    super(props);
    this.state = {
      isModalOpen: false,
      hasAppeared: false,
    };
    this.wrapperRef = React.createRef();
  }

  checkIfOnScreen: IntersectionObserverCallback = (entries, observer) => {
    if (entries[0].isIntersecting) {
      this.setState({ hasAppeared: true });
      observer.disconnect();
    }
  };

  toggleModal = () => {
    this.setState(prev => ({
      isModalOpen: !prev.isModalOpen,
    }));
  };

  componentDidMount() {
    if (this.wrapperRef.current) {
      const observer = new IntersectionObserver(this.checkIfOnScreen, {
        rootMargin: '200px 0px',
      });
      observer.observe(this.wrapperRef.current);
    }
  }

  render() {
    const { isModalOpen, hasAppeared } = this.state;
    return (
      <>
        <div
          className="slack__wrapper"
          aria-hidden={true}
          ref={this.wrapperRef}
        >
          <StaticQuery
            query={graphql`
              query slackWrapperQuery {
                channels: allSlackChannel(
                  filter: {
                    name: { regex: "/^((?!z-visitor-|z-jov).)*$/gi" }
                    is_private: { eq: false }
                  }
                ) {
                  nodes {
                    name
                    num_members
                    topic {
                      value
                    }
                    normalizedMessages {
                      ts
                      text
                      user {
                        real_name
                        display_name
                        image_48
                      }
                      reply_count
                      files
                    }
                  }
                }
              }
            `}
            render={data => {
              if (!hasAppeared || !data.channels) {
                return null;
              }
              const selectedChannel = data.channels.nodes.find(
                ({ name }: ISlackChannel) => name === 'random'
              );
              return (
                <SlackModalContext.Provider
                  value={{
                    isOpen: isModalOpen,
                    toggleModal: this.toggleModal,
                  }}
                >
                  <SlackSidebar
                    hasAppeared={hasAppeared}
                    openModal={this.toggleModal}
                    channels={data.channels.nodes}
                    workspaceName="Data Hackers"
                  />
                  <SlackTopBar
                    workspaceName="Data Hackers"
                    activeChannel={'random'}
                  />
                  {selectedChannel ? <SlackChat {...selectedChannel} /> : null}
                  <SlackModal
                    isOpen={isModalOpen}
                    closeModal={this.toggleModal}
                  />
                </SlackModalContext.Provider>
              );
            }}
          />
        </div>
        {this.props.includeCTA ? (
          <p className="text_center">
            <Button
              onClick={this.toggleModal}
              aria-label="Botão para participar do Slack"
            >
              Participe do Slack!
            </Button>
          </p>
        ) : null}
      </>
    );
  }
}

export default SlackWrapper;
