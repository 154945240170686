export const slackColors = {
  sidebarBg: '#4d394b',
  sidebarInactive: 'rgb(184,176,183)',
  msgBody: '#2c2d30',
  linkColor: '#0576b9',
  linkBg: '#eaf5fc',
  referenceBg: '#eaf5fc',
  lightGray: '#717274',
  channelSelected: '#4C9689',
  directStatus: '#38978D',
  red: '#DC5960',
  buttonHover: '#3E313C',
};

export const slackMisc = {
  maxWidth: '1200px',
  boxShadow: '0 4px 4px rgba(30,0,30,.24)',
  borderRadius: '10px',
  menuPadding: '2px 25px 2px 15px',
  menuFontSize: '16px',
};

export const statusCircle = `
  width: 0.5em;
  height: 0.5em;
  display: inline-block;
  border-radius: 50%;
  background: ${slackColors.directStatus};
`;
