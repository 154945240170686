import * as React from 'react';
import { ISlackChannel } from './SlackWrapper';
import { BellIcon, ChatIcon } from './slackIcons';

export interface ISlackSidebarProps {
  workspaceName: string;
  hasAppeared: boolean;
  channels: ISlackChannel[];
  openModal: (e: React.MouseEvent<HTMLButtonElement>) => any;
}

export const SlackSidebar: React.SFC<ISlackSidebarProps> = props => {
  return (
    <aside className="slack-sidebar__wrapper">
      <button className="slack-sidebar__title" onClick={props.openModal}>
        <span>{props.workspaceName}</span> <BellIcon />
      </button>
      <div className="slack-sidebar__user">Data Hacker (você)</div>
      <button className="slack-sidebar__all-threads" onClick={props.openModal}>
        {<ChatIcon />} Todos os tópicos
      </button>
      <section className="slack-sidebar__channel-wrapper">
        <div className="slack-sidebar__header">Canais</div>
        {props.channels.map(channel => (
          <button
            className={`slack-sidebar__channel-btn ${
              channel.name === 'random' ? 'active' : ''
            }`}
            onClick={props.openModal}
            id={channel.name}
            key={channel.name}
            tabIndex={-1}
          >
            {channel.name}
          </button>
        ))}
      </section>
      <section className="slack-sidebar__channel-wrapper">
        <div className="slack-sidebar__header">Mensagens Diretas</div>
        <button
          className={`slack-sidebar__channel-btn slack-sidebar__direct-btn ${
            props.hasAppeared ? 'appeared' : ''
          }`}
          id="ctaChannel"
          onClick={props.openModal}
        >
          Ei, psiu!
        </button>
      </section>
    </aside>
  );
};

SlackSidebar.displayName = 'SlackSidebar';

export default SlackSidebar;
