import * as React from 'react';

import { MenuDots, MagnifierIcon, Chevron } from './slackIcons';
import { SlackModalContext } from './SlackModal';

export interface ISlackTopBarProps {
  workspaceName: string;
  activeChannel: string;
}

export const SlackTopBar: React.SFC<ISlackTopBarProps> = props => {
  return (
    <aside className="slack__header">
      <SlackModalContext.Consumer>
        {({ toggleModal }) => (
          <>
            <div className="slack__header-top">
              <span>{props.workspaceName}</span>{' '}
              <button onClick={toggleModal}>
                <MagnifierIcon />
                <MenuDots />
              </button>
            </div>
            <button className="slack__header-botom" onClick={toggleModal}>
              <span>{props.activeChannel}</span>
              <Chevron />
            </button>
          </>
        )}
      </SlackModalContext.Consumer>
    </aside>
  );
};

SlackTopBar.displayName = 'SlackTopBar';

export default SlackTopBar;
