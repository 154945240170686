import * as React from 'react';
import { DialogOverlay, DialogContent } from '@reach/dialog';

import LogoSlack from '../icons/LogoSlack';
import { toggleScroll } from '../../utils/dom';
import Button from '../Button';
import { useStaticQuery, graphql } from 'gatsby';

export interface ISlackModalProps {
  isOpen: boolean;
  closeModal: () => any;
}

export const SlackModalContext = React.createContext({
  isOpen: false,
  toggleModal: () => {
    console.error('Erro ao abrir o Slack');
  },
});

export const SlackModal: React.SFC<ISlackModalProps> = props => {
  const { sanityConfig } = useStaticQuery(graphql`
    {
      sanityConfig(_id: { eq: "configId" }) {
        slackJoinUrl
      }
    }
  `);
  if (props.isOpen) {
    toggleScroll(false);
  } else {
    toggleScroll(true);
  }
  return (
    <DialogOverlay
      className="slack-modal__dialog"
      isOpen={props.isOpen}
      onDismiss={props.closeModal}
    >
      <DialogContent className="slack-modal__content">
        <div className="slack-modal__wrapper">
          <div className="slack-modal__logo-wrapper">
            <LogoSlack />
          </div>
          <h2>Junte-se ao nosso canal do Slack</h2>
          <p>
            E acompanhe o dia a dia a dia da comunidade e{' '}
            <b>converse diretamente com especialistas</b> das maiores empresas
            do Brasil!
          </p>
          <Button
            url={sanityConfig.slackJoinUrl}
            aria-label="Botão para participar do Slack"
          >
            Peça um convite
          </Button>
        </div>
      </DialogContent>
    </DialogOverlay>
  );
};

SlackModal.displayName = 'SlackModal';

export default SlackModal;
