import * as React from 'react';

import { removeEmoji } from '../../utils/strings';
import UserIcon from '../icons/UserIcon';
import { slackColors } from './slackTheme';

export interface IChatMetaProps {
  members: number;
  topic?: string;
  name: string;
}

export const ChatMeta: React.SFC<IChatMetaProps> = props => {
  return (
    <aside className="slack-chat-meta__wrapper">
      <div className="slack-chat-meta__title">{props.name}</div>
      <div className="slack-chat-meta__meta-bar">
        <span className="slack-chat-meta__meta-prop">
          <UserIcon color={slackColors.lightGray} width="13px" />{' '}
          {props.members}
        </span>
        <span className="slack-chat-meta__meta-divider" />
        {props.topic ? (
          <span className="slack-chat-meta__meta-prop">
            {removeEmoji(props.topic)}
          </span>
        ) : null}
      </div>
    </aside>
  );
};

ChatMeta.displayName = 'ChatMeta';

export default ChatMeta;
